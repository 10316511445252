.sphere-input {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 40px;
  border: 2px solid transparent;
  outline: 1px solid #d1d7e3;
  border-radius: 0.5rem;
  border-style: solid;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.sphere-input--payment-placeholder input::placeholder {
  color: rgb(117, 117, 117);
}

.sphere-input--textarea {
  height: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.sphere-input--error.sphere-input--error {
  border-color: #f85858;
  outline: none;
}

.sphere-input.sphere-input--error.sphere-input--error {
  outline: none;
}

.sphere-input--disabled.sphere-input--disabled {
  border-color: rgb(243, 244, 246);
  color: rgba(0, 0, 0, 0.5);
}

.sphere-input__start-adornment {
  flex-shrink: 0;
}

.sphere-input__input {
  outline: 0;
  flex: 1 1 0;
  min-width: 0;
  background-color: transparent;
  border: 0;
}

.sphere-input:focus-within.sphere-input:focus-within {
  border: 2px solid #2c61f9;
  box-shadow: 0 0 0 calc(2px) rgba(44, 97, 249, 0.2);
  outline: none;
}
.sphere-input:focus-within.sphere-input:focus-within.sphere-input--error {
  box-shadow: 0 0 0 calc(2px) #ee5b7e80;
  border-color: #f85858;
  outline: none;
}

.sphere-input:hover {
  outline: 1px solid #98a2b3;
}

.sphere-input:focus-within ~ .sphere-input:hover {
  outline: none;
}

.sphere-input__input::placeholder {
  font-size: 0.875rem;
  font-weight: 400;
}

.sphere-input__input--textarea {
  resize: none;
  height: 5rem;
}

.sphere-input--textarea--description {
  position: relative;
  display: flex;
}

.sphere-input__input--textarea--description {
}

.sphere-input__input--textarea--description-char-count {
  position: absolute;
  bottom: 0.2rem;
  right: 0.2rem;
  pointer-events: none;
  background-color: transparent;
  color: #98a2b3;
  font-size: 0.75rem;
  margin-left: 1rem;
}
